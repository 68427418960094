import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect } from 'react';

function App() {

  useEffect(() => {
    handleClick();
    document.title = `You clicked 9 times`;
  });
  function  handleClick() {
    // do something meaningful, Promises, if/else, whatever, and then
    //window.location.assign('https://manblaysaviours.com/#/merchandise?ivorianconnection');
    window.location.assign('https://manblaysaviours.com/#/connections?ivorianconnection')
  
  }
  return (
    <div className="App">
    </div>
  );
}

export default App;
